import { Alert, Button, TextInput, Label } from "flowbite-react";
import { Formik } from "formik";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { useTranslation } from "react-i18next";
import imgLogin from "../../images/authentication/login.jpg";
import imgLogo from "../../images/logo-agorastore.svg";
import React, { useEffect } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { FullScreenSpinner } from "../../components/stories/FullScreenSpinner";
import { trackingService } from "utils/TrackingService";
import { useFeatureFlagCheck } from "dashboards/_common/UseFeatureFlagCheck";

const LoginPage = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { login } = useAuth();
  const [searchParams] = useSearchParams();
  const impersonatorLogin = searchParams.get("ImpersonatorLogin");
  const impersonatorPwd = searchParams.get("ImpersonatorPwd");

  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    if (impersonatorLogin && impersonatorPwd) {
      extracted({ username: impersonatorLogin, password: impersonatorPwd });
    }
  }, [impersonatorLogin, impersonatorPwd]);

  async function extracted(newVar: { password: any; username: any }) {
    try {
      await login(newVar);
      const state = location.state as any;
      if (state?.from) {
        navigate(state.from);
      } else {
        //navigate(`/`);
      }
    } catch (ex) {
      setErrorMessage(
        "Votre email ou votre mot de passe est incorrect. Veuillez les ressaisir."
      );
    }
  }

  return (
    <div
      data-testid={"login-page"}
      className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0"
    >
      <Link
        to=""
        target={"_blank"}
        className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10"
      >
        <img src={"https://cdn.agorastore.fr/agorastore/1b053a187769070dfcf9f572263e01f6-logo_blue.svg"} className="mr-4 h-20" alt="Agorastore Logo" />
      </Link>
      <div className="items-center justify-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-lg 2xl:max:max-w-screen-lg xl:p-0">
        <div className="hidden w-2/3 lg:flex">
          <img className="rounded-l-lg" src={imgLogin} alt="login image" />
        </div>
        <div className="w-full p-6 space-y-8 sm:p-8 lg:p-16 lg:py-0">
          <h2 className="text-2xl font-bold text-black-900 lg:text-3xl">
            {t("loginPage::title")}
          </h2>

          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors: { email?: string } = {};
              if (!values.email) {
                errors.email = t("loginPage::requiredEmail");
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = t("loginPage::invalidEmail");
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const newVar = {
                username: values.email,
                password: values.password,
              };
              await extracted(newVar);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                {errorMessage ? (
                  <Alert color="failure" icon={BiErrorCircle}>
                    {errorMessage}
                  </Alert>
                ) : null}
                <div>
                  <Label htmlFor="email" color="black">
                    {" "}
                    {t("loginPage::email")}
                  </Label>
                  <TextInput
                    data-testid="email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    required
                    autoComplete="email"
                    color="black"
                  />
                  <span className="font-bold text-red-600">
                    {" "}
                    {errors.email && touched.email && errors.email}
                  </span>
                </div>
                <div>
                  <Label htmlFor="password" color="black">
                    {" "}
                    {t("loginPage::password")}
                  </Label>
                  <TextInput
                    type="password"
                    name="password"
                    data-testid="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="••••••••"
                    required
                    autoComplete="current-password"
                    color="black"
                  />
                  <span className="font-bold text-red-600">
                    {errors.password && touched.password && errors.password}
                  </span>
                </div>
                <div className="flex items-start">
                  {/* <div className="flex items-center h-5">
                                <ToggleSwitch id="remember"  name="remember" className="border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                                    required label="Se rappeler de moi"/>
                            </div> */}

                  <Link
                    to="/identity/forgot-password"
                    className="ml-auto text-sm text-primary-700 hover:underline dark:text-primary-500"
                  >
                    {" "}
                    {t("loginPage::passwordLost")}
                  </Link>
                </div>
                <Button
                  data-testid="login-button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("loginPage::login")}
                  {isSubmitting ? (
                    <span className="ml-5">
                      <FullScreenSpinner />
                    </span>
                  ) : null}
                </Button>
                {/*
                                <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                    {t("loginPage::accountNotYetCreated")} <Link to="/identity/signup"
                                                                                 className="text-primary-700 hover:underline dark:text-primary-500">{t("loginPage::register")}</Link>
                                </div>
*/}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
